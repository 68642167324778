<template>
  <div class="area-chat">
    <b-playte-chat
      :config="config"
      :messages="messages"
      :simute-type="simuteType"
      @message-sent="addMessage"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import BPlayteChat from "../components/BPlayteChat.vue";

const apiUrl = process.env.VUE_APP_BASE_URL;

const config = {
  title: "Chat",
  from: "Usuário",
  to: "Bot",
  messageSimulate: "Estou processando sua pergunta...",
};

const messages = ref([]);
const simuteType = ref(false);
const threadId = ref(null);

const addMessage = async (_msg) => {
  messages.value.push(_msg);

  simuteType.value = true;

  try {
    await processMessageUser(_msg.message);
  } catch (error) {
    console.error("Erro ao obter resposta da API:", error);
    messages.value.push({
      message: "Desculpe, não consegui obter a resposta. Tente outra pergunta.",
      from: "Bot",
      to: _msg.from,
      dateCreated: new Date().getTime(),
    });
    saveSessionMessage();
  } finally {
    simuteType.value = false;
  }
};

const processMessageUser = async (_msg) => {
  const body = {
    message: _msg,
  };
  if (threadId.value) {
    body.threadId = threadId.value;
  }
  const data = await requestAI(body);

  if (!threadId.value) {
    threadId.value = data.threadId;
    sessionStorage.setItem("threadId", data.threadId);
  }
  messages.value.push({
    message: data.message,
    from: "Bot",
    to: _msg.from,
    dateCreated: new Date().getTime(),
  });
  saveSessionMessage();
};

const requestAI = async (_body) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      let responseMessage;

      // Adiciona as condições de respostas alternativas
      switch (_body.message.toLowerCase()) {
        case "olá":
        case "ola":
        case "oi":
          responseMessage = "Olá! Como posso te ajudar hoje?";
          break;
        case "como você está?":
          responseMessage = "Eu sou apenas um código, mas estou funcionando perfeitamente!";
          break;
        case "qual é o seu nome?":
          responseMessage = "Eu sou o assistente virtual. Prazer em conhecê-lo!";
          break;
        case "adeus":
        case "tchau":
          responseMessage = "Até logo! Espero que possamos conversar novamente em breve.";
          break;
        case "crie um texto lorem ipsum":
          responseMessage = "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum";
          break;
        case "crie um texto lorem ipsum":
          responseMessage = "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum";
          break;
        default:
          responseMessage = `Não consegui entender a mensagem: ${_body.message}`;
          break;
      }

      resolve({
        message: responseMessage,
        threadId: _body.threadId || "1234567890"
      });
    }, 2000);
  });
};

const saveSessionMessage = () => {
  sessionStorage.setItem("messages", JSON.stringify(messages.value));
};

onMounted(async () => {
  const savedThreadId = sessionStorage.getItem("threadId");
  messages.value = JSON.parse(sessionStorage.getItem("messages")) || [];

  if (!savedThreadId) {
    simuteType.value = true;
    config.messageSimulate = "carregando...";
    await processMessageUser("Olá o que posso ajudar?");
    simuteType.value = false;
    saveSessionMessage();
  } else {
    threadId.value = savedThreadId;
  }
});
</script>

<style scoped>
.area-chat {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style> 

<style>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
</style>