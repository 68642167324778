<template>
  <div class="chat-container">
    <div class="chat-header">
      {{ config.title }}
    </div>

    <div ref="messageContainer" class="chat-messages">
      <div
        v-for="(message, index) in messages"
        :key="index"
        :class="[message.from === config.from ? 'user' : 'other']"
        class="message"
      >
      <div class="footer-message">
          <div class="stop-message">
            <div>
              <span v-html="renderString(message.message)" class="typewriter"></span>
              <span class="message-date">{{ formatDate(message.dateCreated) }}</span>  
            </div>

            <div v-tooltip="text"> 
              <i 
                v-if="message.from === config.to && messageStop"
                @click="cancelTyping" 
                class="mdi mdi-stop-circle" 
              ></i>
            </div>
          </div> 
        </div> 

      </div>

      <div
        v-if="simuteType"
        class="message other"
        v-html="renderString(config.messageSimulate)"
      ></div>
    </div>

    <div class="chat-input-container">
      <textarea
        v-model="newMessage"
        class="chat-input"
        placeholder="Digite uma mensagem..."
        @keyup="sendMessageEvent"
        rows="1"
      ></textarea>
      <button class="send-btn" @click="sendMessage" :disabled="isSending">
        <i v-if="!isSending" class="mdi mdi-arrow-up" />
        <i v-else class="mdi mdi-loading mdi-spin" />
      </button>

    </div>
  </div>
</template>

<script setup>
import { ref, nextTick, defineEmits, onMounted } from "vue";
import { marked } from "marked";


const isSending = ref(false);
const messageStop = ref(true);
const messageContainer = ref(null);
const typingInterval = ref(null);
const messageBeingTyped = ref(null);

const text = ref('Parar explicação')

const emit = defineEmits(["message-sent"]);


const props = defineProps({
  config: {
    type: Object,
    required: true,
  },
  messages: {
    type: Array,
    required: true,
  },
  simuteType: {
    type: Boolean,
    required: true,
  },
});

const newMessage = ref("");

const sendMessage = async () => {
  if (isSending.value || props.simuteType) return; 
  if (newMessage.value.trim()) {
    isSending.value = true; 
    const newMsg = {
      message: newMessage.value.trim(), 
      from: props.config.from,
      to: props.config.to,
      dateCreated: new Date().getTime(),
    };

    newMessage.value = ""; 
    try {
      await confirmMessageSent(newMsg); 
      emit("message-sent", newMsg); 
      setTimeout(() => {
        initScroll();
      }, 3000);
    } catch (error) {
      console.error("Erro ao enviar a mensagem:", error);
    } 
  }
};

const confirmMessageSent = async (message) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const success = true; 

      if (success) {
        resolve();
      } else {
        reject(new Error("Falha ao enviar a mensagem")); 
      }
    }, 1000);
  });
};

const sendMessageEvent = (event) => {
  if (event.type === 'click' || (event.key === "Enter" && (!event.shiftKey || event.ctrlKey))) {
    sendMessage();
  }
};

const scrollToBottom = () => {
  nextTick(() => {
    const container = messageContainer.value;
    console.log(
      container.scrollTop,
      container.clientHeight,
      container.scrollHeight
    );
    if (
      container.scrollTop + container.clientHeight + 50 >=
      container.scrollHeight
    ) {
      container.scrollTop = container.scrollHeight;
    }
  });
};

const initScroll = () => {
  const container = messageContainer.value;
  container.scrollTop = container.scrollHeight;
};

const formatDate = (date) => {
  const now = new Date();
  const dateMsg = new Date(date);

  if (now.getDate() !== dateMsg.getDate()) {
    return dateMsg.toLocaleString(navigator.language, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  return dateMsg.toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
  });
};

const adjustHeight = () => {
  let vhBplayeChat = window.innerHeight * 0.01;
  document.documentElement.style.setProperty(
    "--vhBplayeChat",
    `${vhBplayeChat}px`
  );
};

const renderString = (mensagem) => {
  const lastMessageIndex = props.messages.length - 1;
  const lastMessage = props.messages[lastMessageIndex];
  if (
    lastMessage &&
    lastMessage.message === mensagem &&
    lastMessage.from === props.config.to &&
    (lastMessage.typewriter === undefined || lastMessage.typewriter === false)
  ) {
    const messageFull = mensagem;
    mensagem = "";
    lastMessage.message = "";
    lastMessage.typewriter = true;
    let charsWritten = 0;
    const time = 30;
    const typeMessage = () => {
      
      if (charsWritten < messageFull.length) {
        lastMessage.message += messageFull[charsWritten];
        charsWritten++;
        if (charsWritten % 10 === 0) {
          scrollToBottom();
          
        }
        typingInterval.value = setTimeout(typeMessage, time);
      } else {
        typingInterval.value = null;
        isSending.value = false;
      }
    };
    messageBeingTyped.value = lastMessage;
    typeMessage();
  }

  return marked(mensagem);
};

const cancelTyping = () => {
  if (typingInterval.value) {
    clearTimeout(typingInterval.value); 
    typingInterval.value = null;
    isSending.value = false;
  }
};

window.addEventListener("resize", adjustHeight);
adjustHeight();

onMounted(() => {
  setTimeout(() => {
    initScroll();
  }, 300);
});
</script>


<style scoped>
.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: -webkit-fill-available;
  height: calc(var(--vhBplayeChat, 1vh) * 100 - 20px);
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.chat-header {
  padding: 10px;
  text-align: center;
  background-color: #007bff;
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.chat-messages {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  max-width: 70%;
  clear: both;
  word-break: break-word;
  font-size: 14px;
}

.message * {
  white-space: initial !important;
}

.message.user {
  background-color: #007bff;
  color: white;
  float: right;
}

.message.other {
  background-color: #f1f1f1;
  color: #333;
  float: left;
}

.message-date {
  display: block;
  font-size: 10px;
  color: #f1f1f1;
  margin-top: 2px;
  text-align: right;
}

.other .message-date {
  color: #363636;
}

.chat-input-container {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
  align-items: flex-end;
}

.chat-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  max-height: 120px;
}

.send-btn {
  margin-left: 10px;
  width: 40px;
  height: 40px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.stop-message{
  display: flex;
  justify-content: end;
  align-items: end;
  margin-left: 10px;
}

.stop-message i{
  display: flex;
  height: 15px;
  position: relative;
  font-size: 20px;
  margin-left: 4px;
  color: #00000098;
  cursor: pointer;
}

.footer-message{
  display: flex;
}


.send-btn[disabled] {
  background-color: #ccc;
}

.send-btn:hover {
  background-color: #0056b3;
}

/* Responsividade */
@media (max-width: 768px) {
  .chat-container {
    min-height: -webkit-fill-available;
    height: calc(var(--vhBplayeChat, 1vh) * 100 - 20px);
  }
  .chat-input {
    max-height: 80px;
  }
}
</style>
